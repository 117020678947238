<template>
  <div class="bg-white sm:pt-0 modal-md rounded-lg pb-4 md:pb-6">
    <div class="  px-6 text-center  mt-6 ">
      <p>
        Here are some of the ways you can currently earn credit:
      </p>
    </div>
    <div class="px-4 md:px-6">
      <referral />
      <form @submit.prevent="closeModal" novalidate="true">
        <loading-button
          :is-loading="false"
          :is-enabled="true"
          :is-full="true"
          class="mt-6"
          label="Ok, got it thanks!"
        />
      </form>
    </div>
  </div>
</template>

<script>
import LoadingButton from '@/components/LoadingButton';
import Referral from '@/components/Referral';
export default {
  components: {
    LoadingButton,
    Referral
  },
  methods: {
    closeModal: function() {
      this.$store.dispatch('sendMpEvent', {
        event: 'Closed earn credit',
        properties: {
          location: this.$route.name,
          type: 'EVT'
        }
      });
      this.$parent.$emit('make-close');
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-md {
  max-width: 420px;
  width: auto;
}
</style>
